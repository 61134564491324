.campaign-page {
  max-width: 100%;

  .top-section {
    display: flex;
    width: 100%;
    padding: 40px 0;

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      padding: 60px 0 20px;
    }

    .text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 48px;
        font-family: $opensans;
        font-weight: 800;
        color: #2d2d2d;
        margin: 0 0 20px;
        text-align: left;
      }

      p {
        font-size: 16px;
        font-family: $opensans;
        font-weight: 400;
        color: #8d8d8d;
        margin: 0 0 40px;
      }

      @media (max-width: 500px) {
        order: 2;

        h1 {
          font-size: 24px;
          margin: 0 0 10px;
        }

        p {
          font-size: 14px;
          margin: 0 0 25px;
        }
      }
    }

    .image-section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .bottom-section {
    width: 100%;

    .text-headline {
      h3 {
        font-size: 24px;
        font-family: $opensans;
        font-weight: 800;
        color: #2d2d2d;
        text-transform: capitalize;
      }

      p {
        font-size: 14px;
      }
    }

    .step {
      margin: 2rem 0;
      font-size: 16px;
      font-weight: 600;
      font-family: $opensans;

      @media (max-width: 500px) {
        .hide {
          display: none !important;
        }

        .show {
          display: flex !important;
        }
      }

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6rem;
        width: 100%;
        padding-left: 0px;

        @media (max-width: 500px) {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2rem;
          width: 100%;
        }
      }

      .link-headline {
        cursor: pointer;
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;

        @media (max-width: 500px) {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }

        .step-number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 100%;
          background-color: $border-card;
        }

        &.active {
          .text {
            color: $title-color;
          }
          .step-number {
            background-color: $primary;
            span {
              color: #ffffff;
            }
          }
        }
      }
    }

    .card-campaign {
      border: none;
      text-align: start;
      padding-top: 2rem;

      @media (max-width: 500px) {
        padding-top: 0;
      }

      .card-body {
        padding: 0px;

        .form-add-project {
          min-width: 70%;

          @media (max-width: 500px) {
            .p-field-checkbox {
              width: 150px;
            }
          }

          @media (max-width: 768px) {
            .p-field-checkbox {
              width: 150px;
            }
          }

          @media (max-width: 1024px) {
            .p-field-checkbox {
              width: 160px;
            }
          }
        }

        .dropdown-more {
          font-size: 14px;
          font-weight: 600;
          color: #0099ff;
          cursor: pointer;
          display: flex;
          align-items: center;
          max-width: fit-content;
          margin-bottom: 1rem;
        }

        .p-dropdown {
          max-width: 100%;
        }

        .p-button {
          width: 100%;
        }

        @media (max-width: 500px) {
          .p-inputtext {
            border: 2px solid rgb(235, 237, 243);
            border-radius: 10px !important;

            &:nth-child(3) {
              border-right: none;
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
            }
          }

          .p-dropdown {
            border: none;

            .p-dropdown-trigger {
              border: 2px solid rgb(235, 237, 243);
              border-radius: 10px !important;
              border-left: none;
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
            }
          }

          .p-inputtextarea {
            height: 144px;
            min-height: 40px;
          }
        }

        .campaign-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media (max-width: 500px) {
            display: none;
          }

          div:first-child {
            font-size: 16px;
            font-family: $opensans;
            font-weight: 600 !important;
            color: #2d2d2d;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 1rem;
          }
        }
      }
    }

    .card-custom {
      text-align: left;
      box-shadow: none;
      padding-top: 2rem;

      @media (max-width: 500px) {
        padding-top: 0;
      }

      .card-body {
        padding: 0px;

        .back {
          display: none !important;

          @media (max-width: 500px) {
            display: flex !important;
            margin: 0 0 2rem 0;

            .icon-back {
              width: 18px;
              height: 18px;
            }
          }
        }

        .card-skeleton {
          width: 400px;

          @media (max-width: 500px) {
            width: 100%;
          }
        }

        .info-brief-wrapper {
          display: flex;
          justify-content: end;
          align-items: center;
          width: 100%;

          .info-brief {
            display: flex;
            align-items: center;
            width: 30%;
            font-size: 11px;
            font-family: $opensans;
            font-weight: 400;
            padding: 8px 10px;
            gap: 8px;
            color: #0099ff;
            background-color: #0099ff26;
            margin: 1rem 0;

            @media (max-width: 500px) {
              width: 100%;
            }
          }
        }

        .card-wrapper {
          width: 360px;
          height: 520px;

          @media (max-width: 500px) {
            width: 100%;
            height: 420px;
          }

          .card-title {
            min-height: 20%;
          }

          .card-desc {
            min-height: 60%;
          }

          .card-button {
            min-height: 10%;
          }
        }
      }
    }

    .auth-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-top: 2rem;

      @media (max-width: 500px) {
        padding-top: 0;
      }

      .container {
        padding: 0;

        .p-grid {
          padding: 0;
          display: flex;
          justify-content: center;
          gap: 6rem;

          .benefit {
            padding-top: 0;
            padding-bottom: 1.5rem !important;

            .auth-content {
              flex: 1;
              text-align: left;
              height: 100%;

              .box-content {
                width: 100%;
                padding: 0;

                .info-brief {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  font-size: 16px;
                  font-family: $opensans;
                  font-weight: 400;
                  padding: 8px 10px;
                  gap: 8px;
                  color: #0099ff;
                  background-color: #0099ff26;
                  margin: 0 0 1rem 0;
                }

                ul {
                  li {
                    margin: 1.5rem 0;
                  }
                }
              }
            }
          }

          .auth-register {
            flex: 1;
            text-align: left;
            width: 100%;
            height: 100%;
            margin: 0;

            @media (max-width: 500px) {
              margin: 0;
            }

            .back {
              display: none !important;

              @media (max-width: 500px) {
                display: flex !important;
                margin: 0 0 2rem 0;

                .icon-back {
                  width: 18px;
                  height: 18px;
                }
              }
            }

            .info-brief-mobile {
              display: none;

              @media (max-width: 500px) {
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 14px;
                font-family: $opensans;
                font-weight: 400;
                padding: 8px 10px;
                gap: 8px;
                color: #0099ff;
                background-color: #0099ff26;
                margin: 1rem 0 2rem 0;
              }
            }

            .p-button-lg {
              width: 100%;
            }

            .footer-register {
              margin: 1rem 0;
            }
          }
        }
      }

      @media (max-width: 500px) {
        padding: 0;
      }
    }
  }
}
